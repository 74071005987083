import React from 'react'
import { ThemeOptions } from '@mui/material/styles'
import { createTheme } from '@mui/material/styles'

const themeOptions: ThemeOptions = {
    palette: {
        mode: 'light',
        primary: {
            main: 'rgb(0, 149, 246)',
        },
        secondary: {
            main: '#ffffff',
        },
        background: {
            default: 'rgb(250,250,250)',
            paper: '#ffffff',
        },
        text: {
            primary: 'rgb(38, 38, 38)',
            secondary: 'rgb(38, 38, 38)',
        },
        divider: 'rgb(219, 219, 219)',
    },
    typography: {
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        h2: {
            fontWeight: 400,
        },
        h3: {
            fontWeight: 400,
        },
    },
}

export const useTheme = () => {
    const [theme, setTheme] = React.useState(createTheme(themeOptions))

    const toggleTheme = () => {
        const newPaletteType = theme.palette.mode === 'light' ? 'dark' : 'light'
        setTheme(createTheme({ palette: { mode: newPaletteType } }))
    }

    return {
        theme,
        toggleTheme,
    }
}
