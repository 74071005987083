import * as React from 'react'
import {
    Autocomplete,
    AutocompleteChangeReason,
    Box,
    Grid,
    TextField,
    Typography,
} from '@mui/material'
import { useSearchHospital } from '../hooks/hospitalCollection'
import { Hospital } from '../types/Hospital'
import LocationOnIcon from '@mui/icons-material/LocationOn'

type SearchFieldProps = {
    hospital?: Hospital | null
    handleSelect: (hospital: Hospital) => void
    handleClear?: () => void
}

type SearchFieldState = {
    hospital: Hospital | null
    reason: AutocompleteChangeReason
}

export default function SearchField({
    hospital,
    handleSelect,
    handleClear,
}: SearchFieldProps) {
    const [value, setValue] = React.useState<SearchFieldState>({
        hospital: null,
        reason: 'clear',
    })
    const [inputValue, setInputValue] = React.useState('')
    const [options, setOptions] = React.useState<readonly Hospital[]>([])
    const { hospitals, handleSearchHospital } = useSearchHospital()

    React.useEffect(() => {
        const { hospital, reason } = value

        if (reason === 'clear' && handleClear) {
            handleClear()
        } else if (hospital && reason === 'selectOption') {
            handleSelect(hospital)
        }

        if (inputValue === '') {
            setOptions(hospital ? [hospital] : [])
            return
        } else {
            handleSearchHospital(inputValue)
        }
    }, [value, inputValue])

    React.useEffect(() => {
        const { hospital } = value
        setOptions(hospital ? [hospital, ...hospitals] : hospitals)
    }, [hospitals])

    React.useEffect(() => {
        if (hospital === null) {
            setValue({ hospital: null, reason: 'clear' })
            setInputValue('')
            setOptions([])
        }
    }, [hospital])

    return (
        <Autocomplete
            sx={{ minWidth: 300 }}
            getOptionLabel={(option) => option.HospitalName}
            filterOptions={(x) => x}
            options={options}
            autoComplete
            includeInputInList
            filterSelectedOptions
            value={value.hospital}
            noOptionsText="No locations"
            onChange={(event, newValue, reason) => {
                setValue({ hospital: newValue, reason })
            }}
            onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue)
            }}
            renderInput={(params) => (
                <TextField {...params} label="Hospital name" fullWidth />
            )}
            renderOption={(props, option) => {
                const newProps: any = { ...props, key: option.refId }

                return (
                    <li {...newProps}>
                        <Grid container alignItems="center">
                            <Grid item sx={{ display: 'flex', width: 44 }}>
                                <LocationOnIcon
                                    sx={{ color: 'text.secondary' }}
                                />
                            </Grid>
                            <Grid
                                item
                                sx={{
                                    width: 'calc(100% - 44px)',
                                    wordWrap: 'break-word',
                                }}
                            >
                                <Box
                                    component="span"
                                    sx={{ fontWeight: 'bold' }}
                                >
                                    {option.HospitalName}
                                </Box>
                                <Typography
                                    variant="body2"
                                    color="text.secondary"
                                >
                                    {option.Address}
                                </Typography>
                                <Typography
                                    variant="body2"
                                    color="text.secondary"
                                >
                                    {option.City}, {option.State}
                                </Typography>
                            </Grid>
                        </Grid>
                    </li>
                )
            }}
        />
    )
}
