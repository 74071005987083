import * as React from 'react'
import { Routes, Route, BrowserRouter } from 'react-router-dom'
import Layout from './components/Layout'
import Hospitals from './Hospitals'
import ReviewFeed from './ReviewFeed'
import Review from './Review'
import Raffle from './Raffle'
import SignIn from './SignIn'
import SignUp from './SignUp'
import { HospitalProvider } from './context/Hospitals'

export const App = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Layout />}>
                    <Route index element={<ReviewFeed />} />
                    <Route path="hospitals" element={<Hospitals />} />
                    <Route path="*" element={<h1>Not Found</h1>} />
                </Route>
                <Route
                    path="review"
                    element={<Layout showBanner={false} showNavBar={true} />}
                >
                    <Route
                        index
                        element={
                            <HospitalProvider>
                                <Review />
                            </HospitalProvider>
                        }
                    />
                </Route>
                <Route
                    path="raffle"
                    element={
                        <Layout
                            showAppBar={false}
                            showBanner={false}
                            showNavBar={false}
                        />
                    }
                >
                    <Route index element={<Raffle />} />
                </Route>
                <Route
                    element={<Layout showBanner={false} showNavBar={false} />}
                >
                    <Route path="signin" element={<SignIn />} />
                    <Route path="signup" element={<SignUp />} />
                </Route>
            </Routes>
        </BrowserRouter>
    )
}
