import * as React from 'react'
import {
    addDoc,
    collection,
    doc,
    DocumentData,
    DocumentReference,
    endAt,
    Firestore,
    getDocs,
    orderBy,
    query,
    startAt,
} from 'firebase/firestore'
import { Hospital } from '../types/Hospital'
import { getSession } from './Session'
import { Review } from '../types/Review'

const CollectionName = 'Hospitals'

function lowercaseField(name: string) {
    return name
        .toLowerCase()
        .split(' ')
        .map((s) => s.charAt(0).toUpperCase() + s.substring(1).toLowerCase())
        .join(' ')
}

function transformHospital(hospital: Hospital) {
    return {
        ...hospital,
        HospitalName: lowercaseField(hospital.HospitalName),
        Address: lowercaseField(hospital.Address),
        City: lowercaseField(hospital.City),
    }
}

function getHospitalsByName(db: Firestore) {
    return (name: string): Promise<Hospital[]> => {
        console.log('Searching for: ' + name)

        // Create a query against the collection.
        const q = query(
            collection(db, CollectionName),
            orderBy('HospitalName'),
            startAt(name.toUpperCase()),
            endAt(name.toUpperCase() + '\uf8ff')
        )

        return getDocs(q).then((querySnapshot) => {
            const results = querySnapshot.docs.map((doc) => {
                const data = doc.data() as Hospital
                const refId = doc.id
                return transformHospital({ ...data, refId })
            })
            return results
        })
    }
}

function addReview(db: Firestore) {
    return (hospital: Hospital, review: Review) => {
        console.log('Adding review for: ' + hospital.refId)
        const hospitalDoc = doc(db, CollectionName, hospital.refId)
        return addDoc(collection(hospitalDoc, 'Reviews'), review)
    }
}

type HospitalClient = {
    getHospitalsByName: (name: string) => Promise<Hospital[]>
    addReview: (
        hospital: Hospital,
        review: Review
    ) => Promise<DocumentReference<DocumentData>>
}

const HospitalContext = React.createContext<HospitalClient>({
    getHospitalsByName: () => Promise.resolve([]),
    addReview: () => new Promise(() => {}),
})

export const HospitalProvider = ({ children }: React.PropsWithChildren) => {
    const { db } = getSession()

    const client = {
        getHospitalsByName: getHospitalsByName(db),
        addReview: addReview(db),
    }

    return (
        <HospitalContext.Provider value={client}>
            {children}
        </HospitalContext.Provider>
    )
}

export const getHospitalClient = (): HospitalClient => {
    const context = React.useContext(HospitalContext)

    if (context === undefined) {
        throw new Error('HospitalContext was used outside of its Provider')
    }
    return context
}
