import * as React from 'react'
import { Box, Container, Typography, Snackbar, Alert } from '@mui/material'
import ReviewForm from './components/ReviewForm'
import TextGradient from './components/TextGradient'

enum ReviewStates {
    CLEARED,
    SEARCHING,
    REVIEWING,
    SUCCESSFUL,
    FAILED,
}

// TODO: error message flashes after a successful submission

export default function Review() {
    const alertDuration = 4000

    const [reviewState, setReviewState] = React.useState<ReviewStates>(
        ReviewStates.CLEARED
    )

    const handleSuccessfulSubmit = () => {
        setReviewState(ReviewStates.SUCCESSFUL)
    }

    const handleFailedSubmit = () => {
        setReviewState(ReviewStates.FAILED)
    }

    const handleSuccessAlertClose = () => {
        setReviewState(ReviewStates.CLEARED)
    }

    const handleFailedAlertClose = () => {
        setReviewState(ReviewStates.REVIEWING)
    }

    return (
        <>
            <Box
                sx={{
                    bgcolor: 'background.paper',
                    borderBottom: '1px solid',
                    borderColor: 'divider',
                    pb: 10,
                    pt: 8,
                }}
            >
                <Container maxWidth="md" sx={{ textAlign: 'center' }}>
                    <Typography variant="h3">
                        <TextGradient>Rate hospitals</TextGradient> based on
                        what matters most to you as a traveling nurse.
                    </Typography>
                </Container>
            </Box>
            <Container>
                <Snackbar
                    open={
                        reviewState === ReviewStates.SUCCESSFUL ||
                        reviewState === ReviewStates.FAILED
                    }
                    autoHideDuration={alertDuration}
                    onClose={handleSuccessAlertClose}
                >
                    {reviewState === ReviewStates.SUCCESSFUL ? (
                        <Alert
                            onClose={handleSuccessAlertClose}
                            severity="success"
                            sx={{ width: '100%' }}
                        >
                            Review successfully submitted!
                        </Alert>
                    ) : reviewState === ReviewStates.FAILED ? (
                        <Alert
                            onClose={handleFailedAlertClose}
                            severity="error"
                            sx={{ width: '100%' }}
                        >
                            Review submission failed. Please try again.
                        </Alert>
                    ) : (
                        <div />
                    )}
                </Snackbar>
                <Box
                    sx={{
                        pt: 4,
                        pb: 4,
                    }}
                >
                    <ReviewForm
                        handleSuccess={handleSuccessfulSubmit}
                        handleFailure={handleFailedSubmit}
                    />
                </Box>
            </Container>
        </>
    )
}
