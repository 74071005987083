import * as React from 'react'
import HeroUnit from './components/HeroUnit'
import {
    Box,
    Container,
    Typography,
    Stack,
    TextField,
    Chip,
} from '@mui/material'
import SideBarSection from './components/homeSideBar/SideBarSection'
import DistanceSlider from './components/DistanceSlider'
import SearchField from './components/SearchField'
import { Hospital } from './types/Hospital'
import { useLocalStorage } from './hooks/localStorage'

const cards = [1, 2, 3, 4, 5, 6, 7, 8, 9]

export default function Hospitals() {
    const [filter, storeFilter] = useLocalStorage<number>('selected', 0)

    const [selectedHospital, setSelectedHospital] = React.useState<Hospital>()

    return (
        <main>
            <HeroUnit selected={filter} onFilterChange={storeFilter} />
            <Box
                sx={{
                    pt: 4,
                    pb: 4,
                    mb: 8,
                    borderBottom: '1px solid',
                    borderColor: 'divider',
                }}
            >
                <Container>
                    <Stack
                        direction="row"
                        spacing={2}
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Typography>Have a hospital in mind?</Typography>
                        <SearchField handleSelect={setSelectedHospital} />
                    </Stack>
                </Container>
            </Box>
            <Container>
                <Typography variant="h4" gutterBottom>
                    Explore hospitals
                </Typography>
                <Typography paragraph>
                    Filtering by <b>"Norman, OK - 50 miles"</b>,{' '}
                    <b>"3.5 Star Rating and up"</b>, <b>"Overall Rating"</b>,{' '}
                    <b>“Has doctor on site at night”</b>,{' '}
                    <b>“Trauma level 1”</b>
                </Typography>
                <Stack direction="row" spacing={2}>
                    <Box
                        sx={{
                            width: 400,
                            height: 800,
                            border: '1px solid',
                            borderColor: 'divider',
                            backgroundColor: 'background.paper',
                        }}
                    >
                        <SideBarSection>
                            <Typography variant="h6" gutterBottom>
                                Filter hospitals
                            </Typography>
                            <Typography variant="body2">
                                Showing 1-10 of 2,152 hospitals sorted by
                                relevancy
                            </Typography>
                        </SideBarSection>
                        <SideBarSection>
                            <TextField
                                id="location-field"
                                label="Location"
                                variant="outlined"
                                sx={{ width: '100%', mb: 3 }}
                            />
                            <DistanceSlider />
                        </SideBarSection>
                        <SideBarSection>
                            <Typography variant="h6" gutterBottom>
                                Hospital ratings by category
                            </Typography>
                            <Box>
                                <Chip
                                    label="Overall"
                                    variant="outlined"
                                    sx={{ mr: 1, mb: 1 }}
                                />
                                <Chip
                                    label="Compensation"
                                    variant="outlined"
                                    sx={{ mr: 1, mb: 1 }}
                                />
                                <Chip
                                    label="Staffing"
                                    variant="outlined"
                                    sx={{ mr: 1, mb: 1 }}
                                />
                                <Chip
                                    label="Doctor Treatment"
                                    variant="outlined"
                                    sx={{ mr: 1, mb: 1 }}
                                />
                                <Chip
                                    label="Staff Treatment"
                                    variant="outlined"
                                    sx={{ mr: 1, mb: 1 }}
                                />
                            </Box>
                        </SideBarSection>
                    </Box>
                    <Box
                        sx={{
                            height: 800,
                            width: '100%',
                            border: '1px solid',
                            borderColor: 'divider',
                            backgroundColor: 'background.paper',
                        }}
                    ></Box>
                </Stack>
            </Container>
        </main>
    )
}
