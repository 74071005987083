import * as React from 'react'
import logoGradient from './images/logo-gradient.png'
import { Box, Container, Typography, Snackbar, Alert } from '@mui/material'
import ReviewForm from './components/ReviewForm'
import TextGradient from './components/TextGradient'

enum ReviewStates {
    CLEARED,
    SEARCHING,
    REVIEWING,
    SUCCESSFUL,
    FAILED,
}

export default function Raffle() {
    const alertDuration = 4000

    const [reviewState, setReviewState] = React.useState<ReviewStates>(
        ReviewStates.CLEARED
    )

    const handleSuccessfulSubmit = () => {
        setReviewState(ReviewStates.SUCCESSFUL)
    }

    const handleFailedSubmit = () => {
        setReviewState(ReviewStates.FAILED)
    }

    const handleSuccessAlertClose = () => {
        setReviewState(ReviewStates.CLEARED)
    }

    const handleFailedAlertClose = () => {
        setReviewState(ReviewStates.REVIEWING)
    }

    return (
        <main>
            <Box
                sx={{
                    bgcolor: 'background.paper',
                    borderBottom: '1px solid',
                    borderColor: 'divider',
                    pb: 8,
                    pt: 2,
                }}
            >
                <Container maxWidth="md">
                    <Box sx={{ mb: 6 }}>
                        <img src={logoGradient} height="32" />
                    </Box>
                    <Typography variant="h4">
                        We're building a community of{' '}
                        <TextGradient>traveling nurses</TextGradient> who share
                        their experiences with hospitals to help each other find
                        the best contracts.
                    </Typography>
                    <Typography sx={{ mt: 4 }}>
                        By adding your review, you'll be entered into a raffle
                        for a chance to win a prize! We appreciate your input
                        and can't wait to hear about your experiences.
                    </Typography>
                </Container>
            </Box>
            <Snackbar
                open={
                    reviewState === ReviewStates.SUCCESSFUL ||
                    reviewState === ReviewStates.FAILED
                }
                autoHideDuration={alertDuration}
                onClose={handleSuccessAlertClose}
            >
                {reviewState === ReviewStates.SUCCESSFUL ? (
                    <Alert
                        onClose={handleSuccessAlertClose}
                        severity="success"
                        sx={{ width: '100%' }}
                    >
                        Review successfully submitted!
                    </Alert>
                ) : (
                    <Alert
                        onClose={handleFailedAlertClose}
                        severity="error"
                        sx={{ width: '100%' }}
                    >
                        Review submission failed. Please try again.
                    </Alert>
                )}
            </Snackbar>
            <Container>
                <Box
                    sx={{
                        pt: 4,
                        pb: 4,
                    }}
                >
                    <ReviewForm
                        handleSuccess={handleSuccessfulSubmit}
                        handleFailure={handleFailedSubmit}
                    />
                </Box>
            </Container>
        </main>
    )
}
