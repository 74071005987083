import * as React from 'react'
import { Hospital } from '../types/Hospital'
import { debounce } from '@mui/material/utils'
import { getHospitalClient } from '../context/Hospitals'

export function useSearchHospital() {
    const [hospitals, setHospitals] = React.useState<Hospital[]>([])
    const { getHospitalsByName } = getHospitalClient()

    const handleSearchHospital = React.useMemo(
        () =>
            debounce((value: string) => {
                if (value.length < 3) {
                    setHospitals([])
                    return
                }
                getHospitalsByName(value).then(setHospitals)
            }, 500),
        []
    )

    return { hospitals, handleSearchHospital }
}
