import * as React from 'react'

type TextGradientProps = {
    children: string | React.ReactElement
}

export default function TextGradient({ children }: TextGradientProps) {
    return (
        <span
            style={{
                backgroundImage:
                    'linear-gradient(45deg, #ff0f7b 0%, #f89b29 100%)',
                backgroundRepeat: 'no-repeat',
                color: 'transparent',
                WebkitBackgroundClip: 'text',
            }}
        >
            {children}
        </span>
    )
}
