import { initializeApp } from 'firebase/app'
import {
    getRemoteConfig,
    fetchAndActivate,
    getAll,
} from 'firebase/remote-config'
import React from 'react'

export type FirebaseConfig = Partial<{
    apiKey: string
    authDomain: string
    databaseURL: string
    projectId: string
    storageBucket: string
    messagingSenderId: string
    appId: string
    measurementId: string
}>

export function getFirebaseConfig(): FirebaseConfig {
    return {
        apiKey: process.env.API_KEY,
        authDomain: process.env.AUTH_DOMAIN,
        databaseURL: process.env.DATABASE_URL,
        projectId: process.env.PROJECT_ID,
        storageBucket: process.env.STORAGE_BUCKET,
        messagingSenderId: process.env.MESSAGING_SENDER_ID,
        appId: process.env.APP_ID,
        measurementId: process.env.MEASUREMENT_ID,
    }
}

type RemoteFlags = Record<string, string | boolean | number>

export function useRemoteConfig() {
    const [flags, setFlags] = React.useState<RemoteFlags>({
        auth_enabled: false,
    })

    const firebaseConfig = getFirebaseConfig()
    const app = initializeApp(firebaseConfig)
    const remoteConfig = getRemoteConfig(app)
    remoteConfig.settings.minimumFetchIntervalMillis = 1000

    React.useEffect(() => {
        fetchAndActivate(remoteConfig)
            .then((activated) => {
                if (!activated) console.log('not activated')
                return getAll(remoteConfig)
            })
            .then((remoteFlags) => {
                console.log('remoteFlags', remoteFlags)

                const newFlags: RemoteFlags = { ...flags }

                for (const [key, config] of Object.entries(remoteFlags)) {
                    // const appVer = process.env.REACT_APP_VERSION || '0.0.0'
                    // newFlags[key] = semverSatisfies(appVer, config.asString())
                    newFlags[key] = config.asString()
                }

                console.log('newFlags', newFlags)
                setFlags(newFlags)
            })
            .catch((error) => console.error(error))
    }, [])

    return remoteConfig
}
