import * as React from 'react'
import hero from '../images/hero.png'
import { Box, Container, Typography, Stack, Button } from '@mui/material'
import TextGradient from './TextGradient'

type HeroUnitProps = {
    selected: number
    onFilterChange: (filter: number) => void
}

export default function HeroUnit({ selected, onFilterChange }: HeroUnitProps) {
    const [filter, setFilter] = React.useState<number>(selected)

    React.useEffect(() => {
        onFilterChange(filter)
    }, [filter])

    function getVariant(filter: number) {
        return filter === selected ? 'contained' : 'outlined'
    }

    const buttonHandler = (filter: number) => () => {
        setFilter(filter)
    }

    return (
        <Box
            sx={{
                bgcolor: 'background.paper',
                pt: 6,
                pb: 8,
                borderBottom: '1px solid',
                borderColor: 'divider',
            }}
        >
            <Container>
                <Stack
                    direction="row"
                    spacing={6}
                    justifyContent="center"
                    alignItems="center"
                >
                    <img src={hero} height="300" />
                    <div style={{ width: '100%' }}>
                        <Typography variant="h4" gutterBottom>
                            Find a hospital that works for{' '}
                            <TextGradient>you.</TextGradient>
                        </Typography>
                        <Typography paragraph>
                            Discover what a hospital is really like before you
                            make your next move. Search reviews and ratings, and
                            filter based on the qualities that matter most to
                            your search.
                        </Typography>
                        <Stack sx={{ pt: 1 }} direction="row" spacing={2}>
                            <Button
                                variant={getVariant(1)}
                                onClick={buttonHandler(1)}
                            >
                                Compensation
                            </Button>
                            <Button
                                variant={getVariant(2)}
                                onClick={buttonHandler(2)}
                            >
                                Staffing
                            </Button>
                            <Button
                                variant={getVariant(3)}
                                onClick={buttonHandler(3)}
                            >
                                Friendliness
                            </Button>
                        </Stack>
                    </div>
                </Stack>
            </Container>
        </Box>
    )
}
