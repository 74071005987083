import * as React from 'react'
import RatingField, { RatingFieldProps } from './RatingField'

const labels: { [index: string]: string } = {
    1: 'Poorly',
    2: '',
    3: 'Well',
    4: '',
    5: 'Excellently',
}

export default function DoctorTreatmentRating(props: RatingFieldProps) {
    return (
        <RatingField
            label="Rate your interaction with physicians"
            starLabels={labels}
            {...props}
        />
    )
}
