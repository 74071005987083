import * as React from 'react'
import { Link, useLocation } from 'react-router-dom'
import logo from '../images/logo.png'
import logoGradient from '../images/logo-gradient.png'
import {
    AppBar,
    Container,
    IconButton,
    Toolbar,
    Tabs,
    Tab,
    Box,
} from '@mui/material'
import AccountCircleSharpIcon from '@mui/icons-material/AccountCircleSharp'
import LocalHospitalSharpIcon from '@mui/icons-material/LocalHospitalSharp'
import FeedSharpIcon from '@mui/icons-material/FeedSharp'
import AddCircleSharpIcon from '@mui/icons-material/AddCircleSharp'
import { useAppConfig } from '../context/AppConfig'

type HeaderProps = {
    showNavBar?: boolean
}

export default function Header({ showNavBar = true }: HeaderProps) {
    const [currentLogo, setCurrentLogo] = React.useState(logo)
    // TODO: get this from config
    const authEnabled = true
    console.log('auth_enabled', authEnabled)

    return (
        <>
            <AppBar
                position="static"
                sx={{
                    bgcolor: '#fff',
                    color: 'rgb(38, 38, 38)',
                }}
            >
                <Container>
                    <Toolbar sx={{ px: '16px !important' }}>
                        <Link
                            to="/"
                            onMouseEnter={() => setCurrentLogo(logoGradient)}
                            onMouseOut={() => setCurrentLogo(logo)}
                        >
                            <img src={currentLogo} height="32" />
                        </Link>
                        {authEnabled && (
                            <IconButton
                                href="/signin"
                                color="inherit"
                                sx={{
                                    marginLeft: 'auto',
                                }}
                            >
                                <AccountCircleSharpIcon />
                            </IconButton>
                        )}
                    </Toolbar>
                </Container>
            </AppBar>
            {showNavBar && (
                <Box
                    sx={{
                        borderBottom: '1px solid',
                        borderColor: 'divider',
                        backgroundColor: '#ffffff',
                    }}
                >
                    <Container>
                        <Tabs
                            value={useLocation().pathname}
                            aria-label="nav tabs example"
                        >
                            <Tab
                                label="Reviews"
                                icon={<FeedSharpIcon />}
                                iconPosition="start"
                                value="/"
                                component={Link}
                                to="/"
                            />
                            <Tab
                                label="Hospitals"
                                icon={<LocalHospitalSharpIcon />}
                                iconPosition="start"
                                value="/hospitals"
                                component={Link}
                                to="/hospitals"
                            />
                            <Tab
                                label="Rate"
                                icon={<AddCircleSharpIcon />}
                                iconPosition="start"
                                value="/review"
                                component={Link}
                                to="/review"
                            />
                        </Tabs>
                    </Container>
                </Box>
            )}
        </>
    )
}
