import { Analytics, getAnalytics } from 'firebase/analytics'
import { FirebaseApp, initializeApp } from 'firebase/app'
import { Firestore, getFirestore } from 'firebase/firestore'
import * as React from 'react'
import { useAppConfig } from './AppConfig'

type Session = {
    app: FirebaseApp
    analytics: Analytics
    db: Firestore
}

const SessionContext = React.createContext<Session | undefined>(undefined)

type SessionProps = {
    children: React.ReactNode
}

export const SessionProvider = ({ children }: SessionProps) => {
    const config = useAppConfig()

    const app = initializeApp(config.firebase)
    const analytics = getAnalytics(app)
    const db = getFirestore(app)

    const [session] = React.useState<Session>({ app, analytics, db })

    return (
        <SessionContext.Provider value={session}>
            {children}
        </SessionContext.Provider>
    )
}

export const getSession = (): Session => {
    const context = React.useContext(SessionContext)

    if (context === undefined) {
        throw new Error('useUserContext was used outside of its Provider')
    }
    return context
}
