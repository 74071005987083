import * as React from 'react'
import Select, { SelectProps, SelectChangeEvent } from '@mui/material/Select'
import { MenuItem, FormControl, InputLabel } from '@mui/material'

export type SpecialtySelectorProps = {
    value: string
    onChange: (newValue: string) => void
}

export default function SpecialtySelector(props: SpecialtySelectorProps) {
    const [value, setValue] = React.useState<string>(props.value)

    React.useEffect(() => {
        props.onChange(value)
    }, [value])

    React.useEffect(() => {
        setValue(props.value)
    }, [props.value])

    return (
        <FormControl>
            <InputLabel id="nursing-specialization-label">
                Nursing Specialization
            </InputLabel>
            <Select
                labelId="nursing-specialization-label"
                id="nursing-specialization"
                label="Nursing specialization"
                value={value}
                onChange={(event: SelectChangeEvent) => {
                    setValue(event.target.value as string)
                }}
            >
                <MenuItem value="Med-Surg">Med-Surg</MenuItem>
                <MenuItem value="Intensive Care">Intensive Care</MenuItem>
                <MenuItem value="Telemetry/Step-down">
                    Telemetry/Step-down
                </MenuItem>
                <MenuItem value="Emergency Room">Emergency Room</MenuItem>
                <MenuItem value="Surgical">Surgical</MenuItem>
                <MenuItem value="Endoscopy">Endoscopy</MenuItem>
                <MenuItem value="Interventional Radiology">
                    Interventional Radiology
                </MenuItem>
                <MenuItem value="Labor and Delivery">
                    Labor and Delivery
                </MenuItem>
                <MenuItem value="Pediatrics">Pediatrics</MenuItem>
                <MenuItem value="Other">Other</MenuItem>
            </Select>
        </FormControl>
    )
}
