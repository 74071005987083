import * as React from 'react'
import RatingField, { RatingFieldProps } from './RatingField'

const labels: { [index: string]: string } = {
    1: 'Bad',
    2: '',
    3: 'Okay',
    4: '',
    5: 'Great',
}

export default function OverallRating(props: RatingFieldProps) {
    return <RatingField label="Overall rating" starLabels={labels} {...props} />
}
