import * as React from 'react';
import Box from '@mui/material/Box';
import { Slider, Typography } from '@mui/material';

const marks = [
  {
    value: 50,
    label: '50 mi',
  },
  {
    value: 100,
    label: '',
  },
  {
    value: 150,
    label: '',
  },
  {
    value: 200,
    label: '',
  },
  {
    value: 250,
    label: '',
  },
  {
    value: 300,
    label: '300 mi',
  },
];

export default function DistanceSlider() {
  return (
    <Box sx={{ width: '100%' }}>
      <Typography variant="body2" gutterBottom>Distance from location</Typography>
      <Box sx={{ px: 3 }}>
        <Slider
          aria-label="Restricted values"
          defaultValue={50}
          step={null}
          valueLabelDisplay="auto"
          marks={marks}
          min={50}
          max={300}
        />
      </Box>
    </Box>
  );
}