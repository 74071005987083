import * as React from 'react'
import logoGradient from './images/logo-gradient-full.png'
import landingHero from './images/landing-hero.png'
import {
    Box,
    Typography,
    Stack,
    CssBaseline,
    ThemeProvider,
} from '@mui/material'
import TextGradient from './components/TextGradient'
import json2mq from 'json2mq'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from './hooks/theming'

enum ReviewStates {
    CLEARED,
    SEARCHING,
    REVIEWING,
    SUCCESSFUL,
    FAILED,
}

export default function LandingPage() {
    const matches = useMediaQuery(
        json2mq({
            minWidth: 1920,
        })
    )
    const containerStyle = {
        maxWidth: '1600px',
        width: '100vw',
        margin: '0 auto',
        padding: '',
    }
    containerStyle.padding = matches ? '0 50px' : '0 7.142vw'

    const { theme } = useTheme()

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Stack
                direction="column"
                spacing={2}
                sx={{
                    bgcolor: 'background.paper',
                    height: '100%',
                }}
            >
                <Box sx={{ ...containerStyle, pt: 6 }}>
                    <img src={logoGradient} height="50" />
                </Box>
                <Box sx={{ pt: 12 }}>
                    <Stack
                        direction="row"
                        spacing="8vw"
                        alignItems="center"
                        justifyContent="center"
                        sx={containerStyle}
                    >
                        <div style={{ width: '100%' }}>
                            <Typography
                                sx={{
                                    fontSize: '5.5vw',
                                    lineHeight: '100%',
                                    fontWeight: 400,
                                }}
                            >
                                Hospital reviews from traveling nurses like{' '}
                                <TextGradient>you.</TextGradient>
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: '2vw',
                                    lineHeight: '100%',
                                    fontWeight: 300,
                                    mt: '0.8vw',
                                }}
                            >
                                Coming soon.
                            </Typography>
                        </div>
                        <div
                            style={{
                                aspectRatio: 'auto 1 / 1',
                                maxWidth: '513px',
                                width: '60vw',
                                overflow: 'hidden',
                            }}
                        >
                            <img
                                src={landingHero}
                                style={{ height: '100%', width: '100%' }}
                            />
                        </div>
                    </Stack>
                </Box>
            </Stack>
        </ThemeProvider>
    )
}
