import * as React from 'react'
import {
    Stack,
    Paper,
    Typography,
    TextField,
    Toolbar,
    Button,
    Box,
} from '@mui/material'
import CompensationRating from './ratings/CompensationRating'
import DoctorTreatmentRating from './ratings/DoctorTreatmentRating'
import StaffingRating from './ratings/StaffingRating'
import StaffTreatmentRating from './ratings/StaffTreatmentRating'
import { Hospital } from '../types/Hospital'
import { Review } from '../types/Review'
import OverallRating from './ratings/OverallRating'
import OnboardingRating from './ratings/OnboardingRating'
import LocationRating from './ratings/LocationRating'
import SpecialtySelector from './SpecialtySelector'
import SearchField from './SearchField'
import { useTracking } from '../hooks/tracking'
import { getHospitalClient } from '../context/Hospitals'

type ReviewFormProps = {
    handleSuccess?: () => void
    handleFailure?: (err: Error) => void
}

const reviewDefaults = {
    OverallRating: 3,
    CompensationRating: 3,
    StaffingRating: 3,
    DoctorTreatmentRating: 3,
    StaffTreatmentRating: 3,
    OnboardingRating: 3,
    LocationRating: 3,
    NurseSpecialty: '',
    Email: '',
    Comments: '',
}

export default function ReviewForm({
    handleSuccess,
    handleFailure,
}: ReviewFormProps) {
    const [review, setReview] = React.useState<Review>(reviewDefaults)
    const [selectedHospital, setSelectedHospital] =
        React.useState<Hospital | null>(null)

    const { addReview } = getHospitalClient()
    const { publishEvent } = useTracking()

    const handleSearchClear = () => {
        setSelectedHospital(null)
    }

    const handleSubmit = async () => {
        if (selectedHospital === null) {
            return
        }
        addReview(selectedHospital, review)
            .then(() => {
                publishEvent('review_submitted')
                setSelectedHospital(null)
                setReview({ ...reviewDefaults })
                if (handleSuccess) {
                    handleSuccess()
                }
            })
            .catch((err) => {
                console.log(err)
                if (err instanceof Error && handleFailure) {
                    handleFailure(err)
                }
            })
    }

    return (
        <Stack justifyContent="center" alignItems="center">
            <Paper
                variant="outlined"
                square
                sx={{
                    mb: 20,
                }}
            >
                <Stack
                    direction="column"
                    spacing={1}
                    sx={{
                        py: 4,
                        px: 6,
                        borderBottom: '1px solid',
                        borderColor: 'rgb(239, 239, 239)',
                    }}
                >
                    <Typography sx={{ mb: 1 }}>Leave a review for</Typography>
                    <SearchField
                        hospital={selectedHospital}
                        handleSelect={setSelectedHospital}
                        handleClear={handleSearchClear}
                    />
                </Stack>
                <Stack
                    direction="column"
                    spacing={4}
                    sx={{
                        py: 4,
                        px: 6,
                        borderBottom: '1px solid',
                        borderColor: 'rgb(239, 239, 239)',
                    }}
                >
                    <OverallRating
                        value={review.OverallRating}
                        onChange={(OverallRating) => {
                            setReview({ ...review, OverallRating })
                        }}
                    />
                    <CompensationRating
                        value={review.CompensationRating}
                        onChange={(CompensationRating) => {
                            setReview({ ...review, CompensationRating })
                        }}
                    />
                    <OnboardingRating
                        value={review.OnboardingRating}
                        onChange={(OnboardingRating) =>
                            setReview({ ...review, OnboardingRating })
                        }
                    />
                    <LocationRating
                        value={review.LocationRating}
                        onChange={(LocationRating) =>
                            setReview({ ...review, LocationRating })
                        }
                    />
                    <StaffingRating
                        value={review.StaffingRating}
                        onChange={(StaffingRating) =>
                            setReview({ ...review, StaffingRating })
                        }
                    />
                    <DoctorTreatmentRating
                        value={review.DoctorTreatmentRating}
                        onChange={(DoctorTreatmentRating) =>
                            setReview({ ...review, DoctorTreatmentRating })
                        }
                    />
                    <StaffTreatmentRating
                        value={review.StaffTreatmentRating}
                        onChange={(StaffTreatmentRating) =>
                            setReview({ ...review, StaffTreatmentRating })
                        }
                    />
                    <TextField
                        label="Any additional information you want to share"
                        value={review.Comments}
                        variant="outlined"
                        multiline={true}
                        rows={6}
                        sx={{ width: '600px' }}
                        onChange={(e) => {
                            setReview({
                                ...review,
                                Comments: e.target.value,
                            })
                        }}
                    />
                </Stack>
                <Stack
                    direction="column"
                    spacing={4}
                    sx={{
                        py: 4,
                        px: 6,
                    }}
                >
                    <SpecialtySelector
                        value={review.NurseSpecialty}
                        onChange={(NurseSpecialty) =>
                            setReview({ ...review, NurseSpecialty })
                        }
                    />
                    <Box>
                        <TextField
                            label="Your email address"
                            value={review.Email}
                            sx={{ width: '600px' }}
                            onChange={(e) => {
                                setReview({ ...review, Email: e.target.value })
                            }}
                        />
                        <Typography
                            sx={{
                                fontSize: '10px',
                                fontStyle: 'italic',
                                mt: 1,
                            }}
                        >
                            * This will be used to send your prize
                        </Typography>
                    </Box>
                    <Toolbar disableGutters sx={{ mt: 2 }}>
                        <Button
                            variant="contained"
                            disabled={selectedHospital === null}
                            sx={{ width: '100%' }}
                            onClick={handleSubmit}
                        >
                            Submit
                        </Button>
                    </Toolbar>
                </Stack>
            </Paper>
        </Stack>
    )
}
