import * as React from 'react'
import { Link } from 'react-router-dom'
import { Box, Typography, Container, Button, Stack } from '@mui/material'
import ArrowForwardSharpIcon from '@mui/icons-material/ArrowForwardSharp'

export default function Banner() {
    return (
        <Box
            sx={{
                py: 1,
                backgroundImage:
                    'linear-gradient(45deg, #ff0f7b 0%, #f89b29 100%)',
                backgroundRepeat: 'no-repeat',
                width: '100%',
            }}
        >
            <Container>
                <Link to="/review" style={{ textDecoration: 'none' }}>
                    <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="center"
                        spacing={1}
                        sx={{ color: '#ffffff' }}
                    >
                        <Typography>
                            Help other traveling nurses by rating a hospital
                        </Typography>
                        <ArrowForwardSharpIcon sx={{ fontSize: '20px' }} />
                    </Stack>
                </Link>
            </Container>
        </Box>
    )
}
