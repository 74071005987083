import * as React from 'react'
import RatingField, { RatingFieldProps } from './RatingField'

const labels: { [index: string]: string } = {
    1: 'Under staffed',
    2: '',
    3: 'Adequately staffed',
    4: '',
    5: 'Well staffed',
}

export default function StaffingRating(props: RatingFieldProps) {
    return (
        <RatingField
            label="Rate how well the hospital is staffed"
            starLabels={labels}
            {...props}
        />
    )
}
