import * as React from 'react'
import { createRoot } from 'react-dom/client'
import { App } from './App'
import LandingPage from './LandingPage'
import { AppConfigProvider, useAppConfig } from './context/AppConfig'
import { SessionProvider } from './context/Session'

const root = document.getElementById('root')
if (!root) {
    throw new Error('Root element not found')
}

const Loader = () => {
    const config = useAppConfig()
    return (
        <>
            {config.defaultFlags.maintenanceEnabled ? <LandingPage /> : <App />}
        </>
    )
}

createRoot(root).render(
    <React.StrictMode>
        <AppConfigProvider>
            <SessionProvider>
                <Loader />
            </SessionProvider>
        </AppConfigProvider>
    </React.StrictMode>
)
