import * as React from 'react'
import { ThemeProvider } from '@emotion/react'
import { CssBaseline } from '@mui/material'
import Footer from './Footer'
import Header from './Header'
import { Outlet } from 'react-router-dom'
import Banner from './Banner'
import { useTheme } from '../hooks/theming'

type LayoutProps = {
    showAppBar?: boolean
    showBanner?: boolean
    showNavBar?: boolean
}

export default function Layout({
    showAppBar = true,
    showBanner = true,
    showNavBar = true,
}: LayoutProps) {
    const { theme } = useTheme()

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            {showBanner && <Banner />}
            {showAppBar && <Header showNavBar={showNavBar} />}
            <Outlet />
            <Footer />
        </ThemeProvider>
    )
}
