import * as React from 'react'
import reviewsHero from './images/reviews-hero.png'
import star from './images/star.png'
import {
    Box,
    Container,
    Typography,
    Stack,
    Card,
    CardContent,
    Rating,
    Collapse,
} from '@mui/material'
import IconButton, { IconButtonProps } from '@mui/material/IconButton'
import { styled } from '@mui/material/styles'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import SearchField from './components/SearchField'
import TextGradient from './components/TextGradient'
import { Hospital } from './types/Hospital'

interface ExpandMoreProps extends IconButtonProps {
    expand: boolean
}

const ExpandMore = styled((props: ExpandMoreProps) => {
    const { expand, ...other } = props
    return <IconButton {...other} />
})(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}))

export default function Review() {
    const [expanded, setExpanded] = React.useState(false)

    const handleExpandClick = () => {
        setExpanded(!expanded)
    }

    const [selectedHospital, setSelectedHospital] = React.useState<Hospital>()

    return (
        <main>
            <Box
                sx={{
                    bgcolor: 'background.paper',
                    borderBottom: '1px solid',
                    borderColor: 'divider',
                    pb: 6,
                    pt: 6,
                }}
            >
                <Container maxWidth="md">
                    <Stack
                        direction="row"
                        spacing={8}
                        justifyContent="center"
                        alignItems="center"
                    >
                        <div style={{ width: '100%' }}>
                            <Typography variant="h3">
                                See hospital reviews from real traveling nurses
                                like <TextGradient>you.</TextGradient>
                            </Typography>
                        </div>
                        <img src={reviewsHero} height="200" />
                    </Stack>
                </Container>
            </Box>
            <Box
                sx={{
                    pt: 4,
                    pb: 4,
                    mb: 8,
                    borderBottom: '1px solid',
                    borderColor: 'divider',
                }}
            >
                <Stack
                    direction="row"
                    spacing={2}
                    justifyContent="center"
                    alignItems="center"
                >
                    <Typography>
                        Want to see reviews for a specific hospital?
                    </Typography>
                    <SearchField handleSelect={setSelectedHospital} />
                </Stack>
            </Box>
            <Container maxWidth="md">
                <Typography variant="h4" sx={{ mb: 2 }}>
                    Recent reviews
                </Typography>
                <Card
                    sx={{
                        width: '100%',
                        py: 1,
                    }}
                >
                    <CardContent>
                        <Stack direction="row">
                            <Box sx={{ width: '100%' }}>
                                <Typography variant="body2">
                                    Review for
                                </Typography>
                                <Typography variant="h4">
                                    Norman Regional
                                </Typography>
                                <Typography>
                                    By <b>Anonymous</b> on <b>12/12/12</b>
                                </Typography>
                                <Stack
                                    direction="row"
                                    spacing={1}
                                    alignItems="center"
                                    sx={{ mt: 1 }}
                                >
                                    <div
                                        style={{
                                            fontSize: '22px',
                                            fontWeight: 'bold',
                                            color: '#ff0f7b',
                                        }}
                                    >
                                        3.5
                                    </div>
                                    <img src={star} height="26" />
                                </Stack>
                            </Box>
                            <Stack
                                alignItems="end"
                                spacing={1}
                                sx={{
                                    width: 500,
                                    color: 'action.active',
                                    mt: 1,
                                }}
                            >
                                <Stack direction="row" spacing={1}>
                                    <Typography variant="body2">
                                        Compensation
                                    </Typography>
                                    <Rating readOnly value={3} size="small" />
                                </Stack>
                                <Stack direction="row" spacing={1}>
                                    <Typography variant="body2">
                                        Staffing
                                    </Typography>
                                    <Rating readOnly value={2} size="small" />
                                </Stack>
                                <Stack direction="row" spacing={1}>
                                    <Typography variant="body2">
                                        Doctor friendliness
                                    </Typography>
                                    <Rating readOnly value={4} size="small" />
                                </Stack>
                                <Stack direction="row" spacing={1}>
                                    <Typography variant="body2">
                                        Staff friendliness
                                    </Typography>
                                    <Rating readOnly value={5} size="small" />
                                </Stack>
                            </Stack>
                        </Stack>
                    </CardContent>
                    <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="center"
                    >
                        <ExpandMore
                            expand={expanded}
                            onClick={handleExpandClick}
                            aria-expanded={expanded}
                            aria-label="show more"
                        >
                            <ExpandMoreIcon />
                        </ExpandMore>
                    </Stack>
                    <Collapse in={expanded} timeout="auto" unmountOnExit>
                        <CardContent>
                            <Typography paragraph>
                                Lorem ipsum dolor, sit amet consectetur
                                adipisicing elit. Corporis officia veniam
                                tempore voluptatum quos? Itaque maxime doloribus
                                rem doloremque quisquam impedit cumque excepturi
                                alias reiciendis officiis, in tenetur iure
                                explicabo?
                            </Typography>
                        </CardContent>
                    </Collapse>
                </Card>
            </Container>
        </main>
    )
}
