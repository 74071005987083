import * as React from 'react'
import { getDefaultFlags, DefaultFlags } from '../hooks/flags'
import { FirebaseConfig, getFirebaseConfig } from '../hooks/firebase'

type AppConfig = {
    firebase: FirebaseConfig
    defaultFlags: DefaultFlags
}

const ConfigContext = React.createContext<AppConfig>({
    firebase: getFirebaseConfig(),
    defaultFlags: getDefaultFlags(),
})

type ConfigProviderProps = {
    children: React.ReactNode
}

export const AppConfigProvider = ({ children }: ConfigProviderProps) => {
    const [config] = React.useState<AppConfig>({
        firebase: getFirebaseConfig(),
        defaultFlags: getDefaultFlags(),
    })

    return (
        <ConfigContext.Provider value={config}>
            {children}
        </ConfigContext.Provider>
    )
}

export const useAppConfig = () => {
    const context = React.useContext(ConfigContext)
    return context
}
