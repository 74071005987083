import * as React from 'react'
import { Box, Typography, Rating } from '@mui/material'
import StarIcon from '@mui/icons-material/Star'

export type RatingFieldProps = {
    value: number
    onChange: (newValue: number) => void
}

export type RatingLabelProps = {
    label: string
    starLabels: { [index: string]: string }
}

type RatingProps = RatingFieldProps & RatingLabelProps

export default function RatingField(props: RatingProps) {
    const { label, starLabels } = props
    const [value, setValue] = React.useState<number>(props.value)
    const [hover, setHover] = React.useState(-1)

    React.useEffect(() => {
        props.onChange(value)
    }, [value])

    React.useEffect(() => {
        setValue(props.value)
    }, [props.value])

    const getLabelText = (value: number) =>
        `${value} Star${value !== 1 ? 's' : ''}, ${starLabels[value]}`

    return (
        <Box>
            <Typography component="legend" gutterBottom>
                {label}
            </Typography>
            <Box
                sx={{
                    width: 400,
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                <Rating
                    value={value}
                    getLabelText={getLabelText}
                    onChange={(event, newValue) => {
                        if (newValue !== null) setValue(newValue)
                    }}
                    onChangeActive={(event, newHover) => {
                        setHover(newHover)
                    }}
                    emptyIcon={
                        <StarIcon
                            style={{ opacity: 0.55 }}
                            fontSize="inherit"
                        />
                    }
                />
                {value !== null && (
                    <Box
                        sx={{
                            ml: 1,
                            color: 'action.active',
                            fontSize: '12px',
                        }}
                    >
                        {starLabels[hover !== -1 ? hover : value]}
                    </Box>
                )}
            </Box>
        </Box>
    )
}
