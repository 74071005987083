import * as React from 'react'
import RatingField, { RatingFieldProps } from './RatingField'

const labels: { [index: string]: string } = {
    1: 'Below my expectations',
    2: '',
    3: 'Met my expectations',
    4: '',
    5: 'Exceeded my expectations',
}

export default function CompensationRating(props: RatingFieldProps) {
    return (
        <RatingField
            label="Rate your compensation"
            starLabels={labels}
            {...props}
        />
    )
}
