import * as React from 'react'
import { logEvent } from 'firebase/analytics'
import { getSession } from '../context/Session'

export const useTracking = () => {
    const { analytics } = getSession()

    const publishEvent = React.useCallback((event: string, data?: object) => {
        logEvent(analytics, event, data)
    }, [])

    return { publishEvent }
}
