import * as React from 'react'
import {
  Box,
} from '@mui/material'

type SideBarSectionProps = {
  children: React.ReactNode
}

export default function SideBarSection({ children }: SideBarSectionProps) {
    return (
      <Box sx={{
        px: 2,
        py: 4,
        width: '100%',
        borderBottom: '1px solid',
        borderColor: 'divider',
      }}>
          {children}
      </Box>
    )
}