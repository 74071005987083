import * as React from 'react'
import footerPaint from '../images/footer-paint.png'
import { Box, Typography } from '@mui/material'
import Copyright from './Copyright'

export default function Footer() {
    return (
        <Box sx={{
            pt: 8,
            height: '200px',
          }}
          component="footer"
        >
            <Typography
                variant="subtitle1"
                align="center"
                color="text.secondary"
                component="p"
            >
                Something here to give the footer a purpose!
            </Typography>
            <Copyright />
        </Box>
    )
}
